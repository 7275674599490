import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { useTheme } from "@emotion/react";
import { gql, useMutation } from "@apollo/client";
import { getAlternateLang } from "../utils/alternate-lang";
import SEO from "../components/seo";
import { gtmPushUserInfo } from "../services/auth";

export const query = graphql`
  query ForgottenPassword($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ForgottenPassword = (props) => {
  const theme = useTheme();
  const { language, t } = useI18next();
  const FORGOTTEN_PASSWORD = gql`
    mutation ($email: String!) {
      forgotPassword(email: $email) {
        ok
      }
    }
  `;
  const [forgotPassword] = useMutation(FORGOTTEN_PASSWORD);
  const [email, setEmail] = useState("");
  const [showSuccessMessage, setShowSucessMessage] = useState(false);
  const handleChange = (prop) => (event) => {
    setEmail(event.target.value);
  };
  const [showMessageUserDoesNotExist, setShowMessageUserDoesNotExist] =
    useState(false);
  const handleSubmit = (event) => {
    forgotPassword({
      variables: {
        email: email,
      },
    })
      .then((data) => {
        // Handle success.
        console.log("forgotten password, send email : success ", data);
        setShowSucessMessage(true);
        return;
      })
      .catch((error) => {
        // Handle error.
        console.error(
          "forgotten password, send email : an error occurred: %o",
          error.graphQLErrors[0]?.extensions?.exception?.data?.message[0]
            ?.messages[0]?.message
        );
        if (
          error.graphQLErrors[0]?.extensions?.exception?.data?.message[0]
            ?.messages[0]?.message === "This email does not exist."
        )
          setShowMessageUserDoesNotExist(true);
      });

    return false;
  };

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "forgotten-password",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  const seo = {
    metaTitle: t("nav.forgottenPassword"),
    metaDescription: t("nav.forgottenPassword").toLocaleLowerCase(),
    linkAlternateHref: "/" + getAlternateLang(language) + "/forgotten-password",
    linkHrefLang: getAlternateLang(language),
    noindex: true,
  };

  return (
    <Box
      sx={{
        [theme.breakpoints.down("xl")]: {
          maxWidth: "100vw",
        },
      }}
    >
      <SEO seo={seo} />
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          py: 8,
          gap: 8,
        }}
      >
        {!showSuccessMessage ? (
          <div>
            {props.location.state?.prevPath !== "/profile-edit" ? (
              <Link
                underline="hover"
                to={`/login/`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  marginBottom: "90px",
                }}
              >
                <ArrowBackIosNew />
                <Typography
                  style={{
                    color: theme.palette.grey.servier_1,
                  }}
                >
                  {t("login.backLoginPage")}
                </Typography>
              </Link>
            ) : (
              <Link
                underline="hover"
                to={`/profile-edit/`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                <ArrowBackIosNew />
                <Typography style={{ color: theme.palette.grey.servier_1 }}>
                  {t("profile.backProfileEdition")}
                </Typography>
              </Link>
            )}
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
                gap: 3,
              }}
            >
              <Typography variant="h1" style={{ marginBottom: "70px" }}>
                {t("login.resetPassword")}
              </Typography>
              <Typography varaint="body1">
                {t("login.pleaseEnterYourEmail")}
              </Typography>
              <FormControl sx={{ width: "35ch" }} variant="standard">
                <InputLabel htmlFor="email">{t("login.email")}</InputLabel>
                <Input
                  id="email"
                  value={email}
                  onChange={handleChange("email")}
                />
              </FormControl>
              {showMessageUserDoesNotExist && (
                <div>{t("login.thisUserDoesNotExist")}</div>
              )}
              <FormControl
                sx={{ width: "35ch", my: "30px" }}
                variant="standard"
              >
                <Button
                  disabled={!email}
                  variant="contained"
                  style={{
                    letterSpacing: "0.1em",
                    marginBottom: "1em",
                  }}
                  onClick={handleSubmit}
                >
                  {t("login.resetPassword")}
                </Button>
              </FormControl>
            </Container>
          </div>
        ) : (
          <div>
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
                gap: 8,
              }}
            >
              <Typography variant="h1">{t("login.resetPassword")}</Typography>
              <Alert style={{ margin: "0 !important" }}>
                {t("login.successSendingMail")}
              </Alert>
            </Container>
          </div>
        )}
      </Container>
    </Box>
  );
};

export default ForgottenPassword;
